import React from "react"
import Helmet from "react-helmet"

import Provider from "../Provider"
import SEO from "../seo"
import Header from "../Header/Header"

import HeroNavigation from "../Roadmap/HeroNavigation"
import RoadmapRender from "../Roadmap/RoadmapRender"
import { globalWindow } from "../../utils/constants"
import Footer from "../Footer/Footer"

const SuggestionBox: React.FC<{ boardToken: string; basePath: string }> = ({
  boardToken,
  basePath,
}) => {
  return (
    <>
      <Helmet>
        <script>
          {`!function(w,d,i,s){function l(){if(!d.getElementById(i)){var f=d.getElementsByTagName(s)[0],e=d.createElement(s);e.type="text/javascript",e.async=!0,e.src="https://canny.io/sdk.js",f.parentNode.insertBefore(e,f)}}if("function"!=typeof w.Canny){var c=function(){c.q.push(arguments)};c.q=[],w.Canny=c,"complete"===d.readyState?l():w.attachEvent?w.attachEvent("onload",l):w.addEventListener("load",l,!1)}}(window,document,"canny-jssdk","script");`}
        </script>

        <script>
          {`
            Canny('render', {
              boardToken: '${boardToken}',
              basePath: '${
                globalWindow ? (global as any).window.location.pathname : ""
              }'
            });
          `}
        </script>
      </Helmet>

      <Provider>
        <SEO />
        <Header bgColor="#ffffff" />

        <HeroNavigation basePath={basePath} />
        <RoadmapRender />
        <Footer />
      </Provider>
    </>
  )
}

export default SuggestionBox
