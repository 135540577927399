import React from "react"

import getColumnSize from "../../utils/getColumnSize"
import styled, { css } from "../styled"
import { Container } from "../UI/Grid/Grid"

const ContainerRoadMap = styled.div`
  padding-top: 5em;
  background: ${({ theme: { colors } }) => colors.grayscale.light};
`

const Roadmap = styled.div`
  ${({ theme: { media } }) => css`
    ${media.above("xlarge")} {
      margin-left: ${getColumnSize(1)};
      margin-right: ${getColumnSize(1)};
    }
  `}
`

const RoadmapRender: React.FC = () => {
  return (
    <ContainerRoadMap>
      <Container>
        <Roadmap data-canny />
      </Container>
    </ContainerRoadMap>
  )
}

export default RoadmapRender
