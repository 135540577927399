import React from "react"
import { MainTitle, Description } from "../UI/Typography"
import { graphql, useStaticQuery, Link } from "gatsby"

import styled, { css } from "../styled"
import getColumnSize from "../../utils/getColumnSize"
import { Container } from "../UI/Grid/Grid"
import Img, { FluidObject } from "gatsby-image"
import LeaveArrow from "../../../content/images/leave-icon-black.png"

const Hero = styled.div`
  border-bottom: 1px solid
    ${({ theme: { colors } }) => `${colors.grayscale.mediumLight}30`};
  background-color: ${({ theme: { colors } }) => colors.grayscale.white};
`

const HeroContainer = styled.div`
  margin-top: 0;
  display: flex;

  ${({ theme: { media } }) => css`
    ${media.above("medium")} {
      width: ${getColumnSize(8)};
    }

    ${media.above("large")} {
      margin-top: 0;
      margin-left: ${getColumnSize(1)};
      width: 100%;
    }
  `}
`

const Title = styled(MainTitle)`
  margin-top: 32px;
  margin-bottom: 1.25rem;

  ${({ theme: { media } }) => css`
    ${media.above("large")} {
      margin-top: 80px;
    }
  `}
`

const Copy = styled(Description)`
  margin-bottom: 5rem;

  ${({ theme: { media } }) => css`
    ${media.above("xlarge")} {
      padding-right: ${getColumnSize(2)};
    }
  `}
`

interface TabItemProp {
  active?: boolean
  [key: string]: any
}

const TabItem = styled(Link)<TabItemProp>`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  transform: translateY(-1px);
  margin-bottom: -1px;
  margin-left: 2em;
  position: relative;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  transition: ${({ theme: { transition } }) => transition.ease(200)};
  border-bottom: 1px solid
    ${({ theme: { colors }, active }) =>
      active ? colors.blue.default : "transparent"};
  color: ${({ theme: { colors }, active }) =>
    active ? colors.blue.default : colors.grayscale.dark};

  &:first-child {
    margin-left: 0;
  }

  display: ${props => (props.goesOut ? "inline-flex" : "inline-block")};

  &:after {
    display: ${props => (props.goesOut ? "block" : "none")};
    content: "";
    position: relative;
    background-image: url(${LeaveArrow});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: 4px;
    width: 8px;
    height: 8px;
    transition: ${({ theme: { transition } }) => transition.ease(200)};
  }

  &:hover {
    opacity: 0.5;

    &:after {
      transform: translate(2px, -2px);
    }
  }

  img {
    margin-top: -0.6em;
    margin-left: 0.4em;
    display: inline-block;
  }

  ${({ theme: { media } }) => css`
    ${media.above("large")} {
      transform: translateY(0);
    }
  `}
`

const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;

  max-width: 100vw;
  margin-left: -1.25em;
  margin-right: -1.25em;

  padding-left: 1.25em;
  padding-right: 1.25em;

  overflow: auto;
  -webkit-overflow-scrolling: touch;

  ${({ theme: { media } }) => css`
    ${media.above("small")} {
      overflow: visible;
      margin: 0;
      padding: 0;
    }

    ${media.above("xlarge")} {
      margin-left: ${getColumnSize(1)};
      margin-right: ${getColumnSize(1)};
    }
  `}
`

const NavigationColumn = styled.div`
  flex-wrap: nowrap;
  display: flex;
  white-space: nowrap;
`

const Content = styled.div`
  width: ${getColumnSize(12)};
  padding-right: 20px;

  ${({ theme }) => theme.media.above("large")} {
    width: ${getColumnSize(6)};
  }
`
const ImageWrapper = styled.div`
  width: ${getColumnSize(4)};
  max-width: 358px;
  transform: scaleX(-1);
  display: none;

  ${({ theme }) => theme.media.above("large")} {
    display: block;
  }
`

const HeroNavigation: React.FC<{ basePath: string }> = ({ basePath }) => {
  const {
    contentYaml: { suggestionBox },
  }: Content = useStaticQuery(query)

  return (
    <Hero>
      <Container>
        <HeroContainer>
          <Content>
            <Title>
              {suggestionBox.hero.title
                .split("\n")
                .filter(Boolean)
                .map(item => {
                  return [item, <br />]
                })}
            </Title>
            <Copy>{suggestionBox.hero.copy}</Copy>
          </Content>
          <ImageWrapper>
            <Img fluid={suggestionBox.hero.image.childImageSharp.fluid} />
          </ImageWrapper>
        </HeroContainer>

        <Navigation>
          <NavigationColumn>
            <TabItem
              to="/suggest-feature"
              active={basePath === "suggest-feature"}
            >
              Suggest Feature
            </TabItem>
            <TabItem
              to="/propose-integration"
              active={basePath === "propose-integration"}
            >
              Propose Integration
            </TabItem>
            <TabItem
              to="/request-language"
              active={basePath === "request-language"}
            >
              Request Language
            </TabItem>
          </NavigationColumn>

          <TabItem
            as="a"
            href="https://ethereum-world.canny.io/"
            target="_blank"
            goesOut
          >
            Roadmap
          </TabItem>
        </Navigation>
      </Container>
    </Hero>
  )
}

interface Content {
  contentYaml: {
    suggestionBox: {
      hero: {
        title: string
        copy: string
        image: {
          childImageSharp: {
            fluid: FluidObject
          }
        }
      }
    }
  }
}

const query = graphql`
  query SuggestionBox {
    contentYaml(homepage: { button: { ne: null } }) {
      suggestionBox {
        hero {
          title
          copy
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`
export default HeroNavigation
