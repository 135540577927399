import React from "react"

import Template from "../components/Roadmap/Template"

const SuggestionBox: React.FC = () => {
  return (
    <Template
      basePath="propose-integration"
      boardToken="53d4f2bb-d916-bd4a-10b3-2225c4b9ab86"
    />
  )
}

export default SuggestionBox
