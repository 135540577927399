import * as React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import imageShare from "../images/opengraph.png"

interface ISeo {
  description?: string
  lang?: string
  meta?: HTMLMetaElement[]
  keywords?: string[]
  title?: string
}

const SEO: React.FC<ISeo> = ({ description, title }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  const baseUrl = site.siteMetadata.siteUrl
  const pageTitle = title || site.siteMetadata.title
  const pageDescription = description || site.siteMetadata.description
  const image = `${baseUrl}${imageShare}`

  return (
    <Helmet>
      <html lang="en" />

      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={pageDescription} />
      <meta name="twitter:url" content={baseUrl} />
      <meta name="twitter:image" content={image} />

      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={pageDescription} />
      <meta property="og:url" content={baseUrl} />
      <meta property="og:image" content={image} />

      <link rel="dns-prefetch" href="https://widget.canny.io" />
      <link rel="dns-prefetch" href="https://canny.io" />
    </Helmet>
  )
}

export default SEO
